@font-face {
  font-family: 'Reckoner';
  src: url('../fonts/Reckoner_Bold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'RedditSans';
  src: url('../fonts/RedditSans-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'RedditSans';
  src: url('../fonts/RedditSans-Bold.ttf');
  font-weight: 600;
  font-style: normal;
}
/* PAGES */
.page--livecontent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.page--livecontent .btn--back {
  position: absolute;
}
.page--livecontent #top_bg {
  max-width: 100%;
  display: block;
}
.page--livecontent .body-container {
  background: url(../img/content-background.png) no-repeat center top;
  background-size: cover;
  padding: 20px 30px 70px;
  flex: 1;
}
.page--livecontent h1 {
  position: relative;
  padding: 10px 0;
  width: calc(100% - 9px);
  float: left;
  font-size: 20px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #000000;
}
.page--livecontent h1 #date {
  float: right;
  margin-right: -15px;
  color: #000000;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.page--livecontent h2 {
  float: left;
  margin-top: 10px;
  font-size: 14px;
  text-transform: uppercase;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.page--livecontent .container--livecontent {
  clear: both;
  padding-top: 20px;
}
.page--livecontent .container--livecontent .variable {
  width: 100%;
  margin-bottom: 0;
  display: block;
  padding: 14px;
  font-size: 23px;
  font-weight: 400;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.page--livecontent .container--livecontent .variable[id="startovka.Text"] {
  width: auto;
  display: inline-block;
  font-family: 'Reckoner', sans-serif;
  font-size: 55px;
  font-weight: bold;
  padding: 0 50px;
  color: #000000;
}
.page--livecontent .container--livecontent .variable[id="skola.Text"] {
  color: #000000;
}
.page--livecontent .container--livecontent .variable[id="choreografie.Text"] {
  font-weight: bold;
  color: #000000;
}
.page--livecontent .container--livecontent .variable[id="disciplina.Text"] {
  color: #000000;
}
html,
body {
  width: 100%;
  height: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: RedditSans, sans-serif;
}
body {
  background: url(../img/background.jpg) fixed no-repeat;
  background-size: cover;
}
.text-center {
  text-align: center;
}
.crp-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background: #ffffff;
  text-align: center;
  padding: 7px 0;
}
.gradient {
  position: fixed;
  bottom: 34px;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}
.Loader .gradient {
  bottom: 0;
}
