.empty-flag {
    display: inline-block;
}

.Loader {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    text-align: center;
    padding: 30px;
}

.Loader .loader-content {
    position: absolute;
    left: 5%;
    top: 15%;
}

.Loader img {
    max-width: 90%;
    display: inline-block;
}

.Loader span {
    margin-top: 30px;
    display: block;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}